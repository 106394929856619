import React, { useState, useEffect, useRef } from "react";
import Message from "./Message";
import * as XLSX from "xlsx";
import mammoth from "mammoth";
import { getSocket, disconnectSocket } from "../socket"; // Importar el socket
import { PDFDocument } from "pdf-lib";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, TextRun } from "docx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function ChatWindow({ messages, addMessage, setMessages, token, onLogout }) {
  const [input, setInput] = useState("");
  const [currentMessage, setCurrentMessage] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [file, setFile] = useState(null);
  const [fileStickerVisible, setFileStickerVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showThreadPopup, setShowThreadPopup] = useState(false);
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(false); // Nuevo estado para el mensaje de carga
  const [selectMode, setSelectMode] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [showFormatPopup, setShowFormatPopup] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [showPrintedPopup, setShowPrintedPopup] = useState(false);

  useEffect(() => {
    const socket = getSocket(); // Añade esta línea para obtener el socket
    if (!socket || !socket.connected) {
      console.warn("Socket no está conectado.");
      return;
    }

    const handleReceiveMessage = (message) => {
      // Si estamos en estado de carga y recibimos datos, desactivamos el loading
      if (loading) {
        setLoading(false);
      }

      setCurrentMessage({ role: "bot", content: message.content });

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessageIndex = updatedMessages.length - 1;
        const lastMessage = updatedMessages[lastMessageIndex];

        if (lastMessage && lastMessage.role === "bot") {
          // Actualiza el contenido del último mensaje del bot
          updatedMessages[lastMessageIndex] = {
            ...lastMessage,
            content: message.content,
          };
        } else {
          // Agrega un nuevo mensaje del bot
          updatedMessages.push({ role: "bot", content: message.content });
        }

        return updatedMessages;
      });
    };

    socket.on("receive_message", handleReceiveMessage);

    return () => {
      socket.off("receive_message", handleReceiveMessage);
    };
  }, [addMessage, currentMessage]);

  useEffect(() => {
    const socket = getSocket();
    if (!socket || !socket.connected) {
      console.warn("Socket no está conectado.");
      return;
    }

    const handleUserDisabled = () => {
      alert("Su cuenta ha sido deshabilitada.");
      onLogout();
    };

    const handleError = (error) => {
      console.log("Socket error:", error);
      if (
        error &&
        error.data &&
        (error.data.message === "Token inválido." ||
          error.data.message === "Token de autenticación requerido.")
      ) {
        alert(
          "Su sesión ha expirado o es inválida. Por favor, inicie sesión nuevamente."
        );
        disconnectSocket();
        onLogout();
      } else if (
        error.data &&
        error.data.message === "Este usuario ha sido deshabilitado."
      ) {
        alert("Su cuenta ha sido deshabilitada.");
        disconnectSocket();
        onLogout();
      }
    };

    socket.on("user_disabled", handleUserDisabled);
    socket.on("error", handleError);

    return () => {
      socket.off("user_disabled", handleUserDisabled);
      socket.off("error", handleError);
    };
  }, [onLogout]);

  useEffect(() => {
    const socket = getSocket();
    if (!socket || !socket.connected) {
      console.warn("Socket no está conectado.");
      return;
    }

    const handleThreadCreated = () => {
      setShowThreadPopup(true);
      setTimeout(() => {
        setShowThreadPopup(false);
      }, 2000);
    };

    socket.on("thread_created", handleThreadCreated);

    return () => {
      socket.off("thread_created", handleThreadCreated);
    };
  }, []);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension === "pdf") {
        setShowPopup(true); // Mostrar popup si el archivo es PDF
      } else {
        setFile(selectedFile);
        setFileStickerVisible(true); // Mostrar el sticker cuando se selecciona un archivo
      }
    }
  };
  const convertFileToText = async (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    let fileContent = "";

    if (fileExtension === "xlsx") {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      fileContent = XLSX.utils.sheet_to_csv(worksheet);
    } else if (fileExtension === "csv") {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsText(file);
      });
    } else if (fileExtension === "pdf") {
      const data = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(data);
      const pages = pdfDoc.getPages();
      const textPromises = pages.map(async (page) => {
        const text = await page.getTextContent();
        return text.items.map((item) => item.str).join(" ");
      });
      fileContent = (await Promise.all(textPromises)).join("\n");
    } else if (fileExtension === "docx") {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const arrayBuffer = event.target.result;
          mammoth
            .extractRawText({ arrayBuffer: arrayBuffer })
            .then((result) => resolve(result.value))
            .catch((error) => reject("Error al procesar el archivo DOCX."));
        };
        reader.readAsArrayBuffer(file);
      });
    } else if (fileExtension === "txt") {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsText(file);
      });
    } else {
      throw new Error("Formato de archivo no soportado.");
    }
    return fileContent;
  };
  const removeFile = () => {
    setFile(null); // Limpiar el archivo seleccionado
    setFileStickerVisible(false); // Ocultar el sticker
  };
  const sendMessage = async () => {
    const socket = getSocket();

    if (!socket || !socket.connected) {
      console.warn("Socket no está conectado.");
      return;
    }

    if (input.trim() || file) {
      let fileContent = "";

      // Si hay un archivo seleccionado, convertirlo a texto
      if (file) {
        fileContent = await convertFileToText(file);
      }

      // Combinar el mensaje del usuario con el contenido del archivo
      let combinedMessage = input;
      if (fileContent) {
        combinedMessage += `\n\nContenido del archivo:\n${fileContent}`;
      }

      // Enviar el mensaje, el contenido del archivo y el thread_id al backend
      socket.emit("send_message", {
        content: combinedMessage,
        thread_id: threadId,
      });

      // Añadir mensajes al chat
      if (input.trim()) {
        addMessage({ role: "user", content: input });
      }
      if (file) {
        // Mostrar que el archivo ha sido subido
        addMessage({ role: "user", content: `Archivo subido: ${file.name}` });
      }

      setCurrentMessage(null);
      setLoading(true);
      setInput("");
      setFile(null);
      setFileStickerVisible(false);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Evita la inserción de una nueva línea
      sendMessage(); // Llama a la función sendMessage para enviar el mensaje
    }
  };
  const reloadThread = () => {
    const socket = getSocket();
    setThreadId(null); // Reinicia el thread_id para que se genere uno nuevo en el backend

    // Borra todos los mensajes de la interfaz
    setMessages([]); // Limpia los mensajes cuando se recarga el thread

    // Envía la señal para crear un nuevo thread sin contenido
    socket.emit("send_message", {
      new_thread: true, // Indica que se debe crear un nuevo thread
      thread_id: null, // No enviar un thread_id existente
    });
  };
  const handlePrint = () => {
    if (!selectMode) {
      // Entrar en modo de selección
      setSelectMode(true);
    } else {
      if (selectedMessages.length === 0) {
        // Si no hay mensajes seleccionados, cancelar selección
        setSelectMode(false);
      } else {
        // Si hay mensajes seleccionados, mostrar popup de formatos
        setShowFormatPopup(true);
      }
    }
  };
  const handleCheckboxChange = (idx) => {
    if (selectedMessages.includes(idx)) {
      setSelectedMessages(selectedMessages.filter((i) => i !== idx));
    } else {
      setSelectedMessages([...selectedMessages, idx]);
    }
  };
  const handleFormatSelection = (format) => {
    setSelectedFormat(format);
    setShowFormatPopup(false);

    // Generar el archivo basado en el formato seleccionado
    generateFile(format);

    // Resetear el modo de selección y mensajes seleccionados
    setSelectMode(false);
    setSelectedMessages([]);

    // Mostrar el popup de confirmación
    setShowPrintedPopup(true);
  };
  const generateFile = (format) => {
    // Obtener los mensajes seleccionados
    const selectedMsgs = selectedMessages
      .sort((a, b) => a - b)
      .map((idx) => messages[idx]);

    // Preparar el contenido
    let content = "";
    selectedMsgs.forEach((msg) => {
      if (msg.role === "user") {
        content += `Pregunta:\n${msg.content}\n\n`;
      } else if (msg.role === "bot") {
        content += `Respuesta:\n${msg.content}\n\n`;
      }
    });

    if (format === "txt") {
      // Crear un blob y desencadenar la descarga
      const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
      saveAs(blob, "chat-utilia.txt");
    } else if (format === "pdf") {
      const docDefinition = {
        content: [],
      };

      selectedMsgs.forEach((msg) => {
        const role = msg.role === "user" ? "Pregunta:" : "Respuesta:";
        docDefinition.content.push({ text: role, style: "header" });
        docDefinition.content.push({
          text: msg.content,
          margin: [0, 0, 0, 10],
        });
      });

      pdfMake.createPdf(docDefinition).download("chat-utilia.pdf");
    } else if (format === "docx") {
      // Crear un nuevo documento con las secciones definidas
      const doc = new Document({
        styles: {
          paragraphStyles: [
            {
              id: "Comfortaa",
              name: "Comfortaa",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Comfortaa",
                size: 24, // Tamaño de fuente (2 * valor en puntos)
              },
            },
          ],
        },
        sections: [
          {
            children: selectedMsgs.flatMap((msg) => {
              const content = msg.content;
              const role = msg.role === "user" ? "Pregunta:" : "Respuesta:";
              return [
                new Paragraph({
                  style: "Comfortaa",
                  children: [
                    new TextRun({
                      text: role,
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  style: "Comfortaa",
                  children: [
                    new TextRun({
                      text: content,
                    }),
                  ],
                }),
              ];
            }),
          },
        ],
      });

      // Generar y descargar el archivo
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "chat-utilia.docx");
      });
    }
  };

  return (
    <>
      <header className="chat-header">
        <button className="print-btn" onClick={handlePrint}>
          {selectMode
            ? selectedMessages.length > 0
              ? "Aceptar"
              : "Cancelar"
            : "Imprimir Respuestas"}
        </button>

        <div className="header-center">
          <img
            src="https://util-ia.site/img/utilialogo.png"
            alt="Logo UtilIA"
            className="left-logo"
          />
          <h1 id="title">Analizador de Facturas Quirúrgicas</h1>
        </div>
        <button className="logout-btn" onClick={onLogout}>
          Cerrar Sesión
        </button>
      </header>
      <div className="chat-window">
        <div className="messages">
          {showThreadPopup && (
            <div className="popup">
              <div className="popup-content">
                <p>¡Thread creado y recargado correctamente!</p>
              </div>
            </div>
          )}
          {messages.map((msg, idx) => (
            <div
              key={idx}
              className={`message-container ${
                msg.role === "user"
                  ? "user-message-container"
                  : "bot-message-container"
              }`}
            >
              {msg.role === "user" ? (
                <>
                  {/* Mensaje del usuario */}
                  <div className="message-wrapper">
                    {/* Elimina el div con clase "message user-message" */}
                    <Message message={msg} />
                  </div>
                  {/* Checkbox del usuario a la derecha */}
                  {selectMode && (
                    <input
                      type="checkbox"
                      className="message-checkbox user-message-checkbox"
                      checked={selectedMessages.includes(idx)}
                      onChange={() => handleCheckboxChange(idx)}
                    />
                  )}
                </>
              ) : (
                <>
                  {/* Checkbox del asistente a la izquierda */}
                  {selectMode && (
                    <input
                      type="checkbox"
                      className="message-checkbox bot-message-checkbox"
                      checked={selectedMessages.includes(idx)}
                      onChange={() => handleCheckboxChange(idx)}
                    />
                  )}
                  {/* Mensaje del asistente */}
                  <div className="message-wrapper">
                    {/* Elimina el div con clase "message bot-message" */}
                    <Message message={msg} />
                  </div>
                </>
              )}
            </div>
          ))}
          {showFormatPopup && (
            <div className="popup">
              <div className="popup-content">
                <p>Seleccione el formato de archivo:</p>
                <button onClick={() => handleFormatSelection("pdf")}>
                  PDF
                </button>
                <button onClick={() => handleFormatSelection("docx")}>
                  DOCX
                </button>
                <button onClick={() => handleFormatSelection("txt")}>
                  TXT
                </button>
              </div>
            </div>
          )}
          {showPrintedPopup && (
            <div className="popup">
              <div className="popup-content">
                <p>¡Respuestas ya Impresas!</p>
                <button onClick={() => setShowPrintedPopup(false)}>
                  Cerrar
                </button>
              </div>
            </div>
          )}
          {loading && (
            <div className="message-container bot-message-container">
              <div className="message-wrapper">
                <p>Cargando...</p>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <p>
                  La posibilidad de subir PDFs está deshabilitada en la prueba
                  BETA.
                </p>
                <button onClick={() => setShowPopup(false)}>Cerrar</button>
              </div>
            </div>
          )}
        </div>
        <div className="input-area">
          <div className="file-upload-container">
            <label htmlFor="file-input" className="file-upload-btn">
              <img
                src="https://util-ia.site/img/document.png"
                alt="Subir archivo"
                className="upload-icon"
              />
            </label>
            {fileStickerVisible && (
              <div className="file-sticker">
                <span className="file-name">
                  {file ? file.name.split(".")[0].substring(0, 10) + "..." : ""}
                </span>
                <span className="remove-file" onClick={removeFile}>
                  X
                </span>
              </div>
            )}
            <input
              id="file-input"
              type="file"
              onChange={handleFileChange}
              accept=".xlsx,.csv,.docx,.pdf,.txt"
              style={{ display: "none" }}
            />
          </div>
          <button className="reload-thread-btn" onClick={reloadThread}>
            <img
              src="https://util-ia.site/img/reload.png"
              alt="Recargar Thread"
              className="reload-icon"
            />
          </button>
          <textarea
            rows="3"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Escriba su mensaje..."
          />
          <button className="send-btn" onClick={sendMessage}>
            Mandar
          </button>
        </div>

        {/* Mueve el mensaje fuera de la sección de input */}
        <div className="notice">
          <p>
            Las respuestas están siendo generada por una IA y puede cometer
            errores. Considere verificar información relevante.
          </p>
        </div>
      </div>
    </>
  );
}

export default ChatWindow;
