import io from "socket.io-client";

let socket = null;

export const connectSocket = (token) => {
  const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || window.location.origin;

  socket = io(SOCKET_URL, {
    query: { token }, // Enviar el token como parámetro de consulta
    transports: ["websocket"],
    secure: true, // Asegura conexiones seguras en producción
    reconnection: true, // Habilita la reconexión automática
    reconnectionAttempts: 10, // Número máximo de intentos de reconexión
    reconnectionDelay: 5000, // Tiempo entre intentos de reconexión (en ms)
  });

  socket.on("connect", () => {
    console.log("Conectado al servidor de Socket.IO");
  });

  socket.on("disconnect", () => {
    console.log("Desconectado del servidor de Socket.IO");
  });

  socket.on("connect_error", (err) => {
    console.error("Error en la conexión de Socket.IO:", err);
  });

  socket.on("reconnect_attempt", () => {
    console.log("Intentando reconectar al servidor...");
  });
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const getSocket = () => socket; // Añade esta línea para exportar la instancia actual de socket
