import React, { useState } from "react";
import axios from "axios";
import { connectSocket } from "../socket"; // Importar la función para conectar
import "./Login.css";

const Login = ({ setToken, setIsAdmin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Nuevo estado para mostrar u ocultar contraseña
  const [error, setError] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Alternar el estado
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/login`, {
        username,
        password,
      });
      const token = response.data.access_token;
      setToken(token); // Guardar el token en el estado
      localStorage.setItem("token", token); // Guardar el token en localStorage para mantener la sesión

      // Conectar el socket después de guardar el token
      connectSocket(token); // Pasa el token aquí

      // Verificar si el usuario es admin
      if (response.data.admin) {
        setIsAdmin(true); // Redirige al panel de administración
      } else {
        setIsAdmin(false); // Redirige al chat normal
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setError("Este usuario ha sido deshabilitado.");
      } else {
        setError("Credenciales inválidas. Vuélvelo a intentar.");
      }

      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <div className="login-container">
      <img
        src="https://util-ia.site/img/utilialogo.png"
        alt="UtilIA Logo"
        className="logo"
      />
      <h2>Login</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="input-container">
          <label>Usuario:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            autoComplete="username" // Agrega este atributo
          />
        </div>
        <div className="input-container">
          <label>Contraseña:</label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"} // Cambiar el tipo del input según el estado
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password" // Agrega este atributo
            />
            <span
              className="toggle-password"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              )}
            </span>
          </div>
        </div>
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
      {error && (
        <div className="error-popup">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default Login;
