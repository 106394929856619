import React from "react";
import ReactMarkdown from "react-markdown";
import "./Message.css";

function Message({ message }) {
  const isUser = message.role === "user";

  return (
    <div
      className={`message ${
        message.role === "user" ? "user-message" : "bot-message"
      }`}
    >
      <ReactMarkdown>{message.content}</ReactMarkdown>
    </div>
  );
}

export default Message;
