import React, { useState, useEffect } from "react";
import ChatWindow from "./components/ChatWindow";
import AdminPanel from "./components/AdminPanel";
import Login from "./components/Login";
import { connectSocket, disconnectSocket, getSocket } from "./socket"; // Importar solo las funciones necesarias
import "./ChatWindow.css";

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isAdmin, setIsAdmin] = useState(false);
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem("token");
    setIsAdmin(false);
    disconnectSocket();
    setIsSocketConnected(false);
  };

  // App.js
  // App.js
  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    if (savedToken) {
      setToken(savedToken);
      connectSocket(savedToken); // Conectar el socket con el token

      const socket = getSocket();

      socket.on("connect", () => {
        console.log("Socket conectado");
        setIsSocketConnected(true);
      });

      socket.on("disconnect", (reason) => {
        console.log("Socket desconectado:", reason);
        setIsSocketConnected(false);
        if (reason === "io server disconnect") {
          // El servidor ha forzado la desconexión
          logout();
        }
      });

      socket.on("connect_error", (error) => {
        console.error("Error al conectar el socket:", error);
        console.log("Error data:", error.data);
        setIsSocketConnected(false);
        if (
          error &&
          error.data &&
          (error.data.message === "Token inválido." ||
            error.data.message === "Token de autenticación requerido.")
        ) {
          alert(
            "Su sesión ha expirado o no es válida. Por favor, inicie sesión nuevamente."
          );
          disconnectSocket(); // Detener intentos de reconexión
          logout();
        } else if (
          error.data &&
          error.data.message === "Este usuario ha sido deshabilitado."
        ) {
          alert("Su cuenta ha sido deshabilitada.");
          disconnectSocket(); // Detener intentos de reconexión
          logout();
        }
      });

      return () => {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("connect_error");
      };
    }
  }, [token]);

  if (!token) {
    return <Login setToken={setToken} setIsAdmin={setIsAdmin} />;
  }

  if (isAdmin) {
    return <AdminPanel onLogout={logout} />;
  }

  if (!isSocketConnected) {
    return <div>Conectando al servidor...</div>;
  }

  return (
    <div className="app">
      <ChatWindow
        token={token}
        messages={messages}
        addMessage={addMessage}
        setMessages={setMessages}
        setFile={setFile}
        file={file}
        onLogout={logout}
      />
    </div>
  );
}

export default App;
