import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./AdminPanel.css"; // Importar el archivo CSS

const AdminPanel = ({ onLogout }) => {
  const [newUsername, setNewUsername] = useState(""); // Para crear nuevos usuarios
  const [newPassword, setNewPassword] = useState(""); // Para crear nuevos usuarios
  const [message, setMessage] = useState("");
  const [disabledUsers, setDisabledUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]); // Lista de usuarios activos
  const [editUser, setEditUser] = useState(null); // Usuario a editar
  const [editUsername, setEditUsername] = useState(""); // Nombre de usuario editado
  const [editPassword, setEditPassword] = useState(""); // Contraseña editada
  const API_URL = process.env.REACT_APP_API_URL;

  // Función para crear un nuevo usuario
  const handleCreateUser = async () => {
    try {
      const response = await axios.post(`${API_URL}/create_user`, {
        username: newUsername,
        password: newPassword,
      });
      setMessage(response.data.msg);
      setNewUsername("");
      setNewPassword("");
      fetchUsers(); // Actualizar la lista de usuarios después de crear uno nuevo
    } catch (err) {
      setMessage("Error al crear el usuario.");
    }
  };

  // Función para editar un usuario existente
  const handleEditUser = async (userId) => {
    try {
      const response = await axios.put(`${API_URL}/edit_user`, {
        id: userId,
        username: editUsername,
        password: editPassword,
      });
      setMessage(response.data.msg);
      fetchUsers(); // Actualizar la lista de usuarios
      setEditUser(null); // Resetear el modo de edición
    } catch (err) {
      setMessage("Error al editar el usuario.");
    }
  };

  // Función para eliminar un usuario
  const handleDeleteUser = async (userId) => {
    try {
      const response = await axios.delete(`${API_URL}/delete_user`, {
        data: { id: userId },
      });
      setMessage(response.data.msg);
      fetchUsers(); // Actualizar la lista de usuarios
    } catch (err) {
      setMessage("Error al eliminar el usuario.");
    }
  };

  // Función para deshabilitar un usuario
  const handleDisableUser = async (username) => {
    try {
      const response = await axios.post(`${API_URL}/disable_user`, {
        username: username,
      });
      setMessage(response.data.msg);
      fetchUsers(); // Actualizar la lista de usuarios después de deshabilitar
    } catch (err) {
      setMessage("Error al deshabilitar el usuario.");
    }
  };

  // Función para habilitar un usuario
  const handleEnableUser = async (username) => {
    try {
      const response = await axios.post(`${API_URL}/enable_user`, {
        username: username,
      });
      setMessage(response.data.msg);
      fetchUsers(); // Actualizar la lista de usuarios después de habilitar
    } catch (err) {
      setMessage("Error al habilitar el usuario.");
    }
  };

  // Función para obtener la lista de usuarios activos y deshabilitados
  const fetchUsers = useCallback(async () => {
    try {
      const activeResponse = await axios.get(`${API_URL}/active_users`);
      const disabledResponse = await axios.get(`${API_URL}/disabled_users`);
      setActiveUsers(activeResponse.data.active_users);
      setDisabledUsers(disabledResponse.data.disabled_users);
    } catch (err) {
      setMessage("Error al obtener la lista de usuarios.");
    }
  }, [API_URL]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <div>
      <div className="admin-panel-container">
        <div className="logo-container">
          <img
            src="https://util-ia.site/img/utilialogo.png"
            alt="UtilIA Logo"
          />
        </div>
        <h1>Panel de Administración</h1>

        {/* Crear nuevo usuario */}
        <input
          type="text"
          placeholder="Nuevo nombre de usuario"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Nueva contraseña"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button onClick={handleCreateUser}>Crear Usuario</button>

        {message && <p>{message}</p>}

        <h2>Usuarios Activos</h2>
        <table>
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {activeUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.username} </td>
                <td>
                  {editUser === user.id ? (
                    <>
                      <input
                        type="text"
                        placeholder="Nuevo nombre"
                        value={editUsername}
                        onChange={(e) => setEditUsername(e.target.value)}
                      />
                      <input
                        type="password"
                        placeholder="Nueva contraseña"
                        value={editPassword}
                        onChange={(e) => setEditPassword(e.target.value)}
                      />
                      <button
                        onClick={() => {
                          handleEditUser(user.id);
                          // Limpiar los campos de texto después de editar
                          setEditUsername("");
                          setEditPassword("");
                        }}
                      >
                        Guardar
                      </button>
                      <button onClick={() => setEditUser(null)}>
                        Cancelar
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="actions-container">
                        <button
                          onClick={() => setEditUser(user.id)}
                          className="action-table"
                        >
                          {/* Icono de editar */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            style={{ width: "24px", height: "24px" }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                            />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDisableUser(user.username)}
                          className="action-table"
                        >
                          {/* Icono de deshabilitar */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            style={{ width: "24px", height: "24px" }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                            />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDeleteUser(user.id)}
                          className="action-table"
                        >
                          {/* Icono de eliminar */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            style={{ width: "24px", height: "24px" }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h2>Usuarios Deshabilitados</h2>
        <table>
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {disabledUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.username}</td>
                <td>
                  <button onClick={() => handleEnableUser(user.username)}>
                    Habilitar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button className="logout-btn" onClick={onLogout}>
          Cerrar Sesión
        </button>
      </div>
    </div>
  );
};

export default AdminPanel;
